import React from 'react';
import TopBar from '../../components/TopBar/TopBar';
import Header from '../../components/Header/Header';
import GreenTimePolicy from '../../components/Policies/GreenTimePolicy';

function GreenTimePrivacyPolicy() {
    return (
        <>
            <TopBar />
            <Header class="menu-active" />
            <GreenTimePolicy />
        </>
    );
}

export default GreenTimePrivacyPolicy;
