import React from 'react';
import './BannerPolicy.css';

const GreenTimePolicy = () => {
    return (
        <>
            <div className="banner">
                <div className="image"></div>
                <div className="text-center">Privacy Policy - GreenTime timekeeping</div>
            </div>
            <div className="policy-container container">
                <div className="title">Privacy Policy</div>
                <div className="description">
                    Green Stream Technology Co. LTD ("us", "we", or "our") operates the GreenTime timekeeping (now called "GreenTime")
                    mobile application (the "Service").
                </div>
                <div className="description">
                    This page informs you of our policies regarding the collection, use, and
                    disclosure of personal data when you use our Service and the choices you have
                    associated with that data.
                </div>

                <div className="title">Personal Information</div>
                <div className="description">
                    A user registered to GreenTime only when being required by an organization (now called "the organization")
                    for which the user is working.

                    GreenTime will ask every user to register with name, email address, phone number, email address,
                    profile picture. Other information may be required required by the organization.

                    Your location may also be collected during your working shift, but only when your organization requires.

                    All collected data is only for internal use of your organization.

                    <br/>
                    <br/>
                    <b>Use of Your Personal Data</b>
                    <li>To manage Your registration as a user of the Service.
                        The Personal Data You provide can give You access to different functionalities of the Service
                        that are available to You as a registered user.</li>
                    <li>To contact You by email, telephone calls, SMS, a mobile application's push notifications regarding updates
                        or informative communications related to the functionalities.</li>
                </div>

                <div className="title">Information Collected while Using the Application</div>
                <div className="description">
                    While using GreenTime, in order to provide features of the application and only asked by the Organization for which you are working,
                    GreenTime may collect, with Your prior permission: <b>Information regarding your location</b>.
                    <br/>
                    <br/>
                    We use this information to provide features of the application that your Organization needs.
                    <br/>
                    <br/>
                    You can enable or disable access to this information at any time, through Your Device settings.
                </div>

                <div className="title">Account deletion request</div>
                <div className="description">
                    After registration, your data is managed by your organization.
                    GreenTime provides your organization with a function to delete user data.
                    Anytime you want to have your data deleted, please contact the administrator of your organization.
                </div>

                <div className="title">Choice/Opt-Out</div>
                <div className="description">
                    GreenTime provides users the opportunity to opt-in to receiving communications (in-app, email...) from us at
                    the point where we request information about the user. GreenTime give users the
                    options for changing communication channels in the mobile app. I you want to remove your data,
                    please contact the administrator of your organization (see section <b>Account deletion request</b> above)
                </div>

                <div className="title">Your IP Address</div>
                <div>
                    GreenTime may use your IP address to help diagnose problems with our server, and to administer our service.
                    GreenTime may automatically collect other information such as platform type, and connection speed.
                </div>

                <div className="title">Security</div>
                <div className="description">
                    GreenTime has instituted security measures to protect the loss, misuse and alteration of the
                    information under our control.
                    Specifically,
                    (i) our network and database are highly secure;
                    (ii) only authorized employees have access to contact data;
                    (iii) all employees are educated on our privacy policies;
                    (iv) all employees agree to adhere to our privacy policies as a condition of employment;
                    (v) our network and database are password protected and are behind both a firewall and proxy server
                    to ensure security;
                    (vi) our web security is under on-going scrutiny and review to ensure that it meets the highest
                    standards.
                </div>

                <div className="title">Changes to This Privacy Policy</div>
                <div className="description">
                    We may update our Privacy Policy from time to time. Thus, we advise you to
                    review this page periodically for any changes. We will notify you of any changes
                    by posting the new Privacy Policy on this page. These changes are effective
                    immediately after they are posted on this page.
                </div>

                <div className="title">Contact Us</div>
                <div className="description">
                    If you have any questions or suggestions about our Privacy Policy, do not
                    hesitate to contact us.
                </div>
            </div>
        </>
    );
};

export default GreenTimePolicy;
