import React, { FC, useTransition, useRef, useEffect } from 'react';
import './Clients.css';
import clientImg1 from '../../asset/img/clients/client-1.png';
import clientImg2 from '../../asset/img/clients/client-2.png';
import clientImg3 from '../../asset/img/clients/client-3.png';
import clientImg4 from '../../asset/img/clients/client-4.png';
import clientImg5 from '../../asset/img/clients/client-5.png';
import clientImg6 from '../../asset/img/clients/client-6.png';
import clientImg7 from '../../asset/img/clients/client-7.png';
import clientImg8 from '../../asset/img/clients/client-8.png';

import tomia from '../../asset/img/clients/logo-2-300x96.png';
import navison from '../../asset/img/clients/navisonlogo.png';
import qtsc from '../../asset/img/clients/QTSC-Quality-Tech-Solution-Complex.jpg';
import rfthings from '../../asset/img/clients/Logo-RF-Thinsgs-White.png';

import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import FadeAnimation from '../FadeAnimation/FadeAnimation';

// import OwlCarousel from 'react-owl-carousel';

interface ClientsProps {}

const Clients: FC<ClientsProps> = () => {
    var settings = {
        arrows: false,
        dots: true,
        // dotsClass: 'asdhu',
        infinite: true,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 6,
    };

    const { t } = useTranslation();
    return (
        <section id="clients" className="wow fadeInUp">
            <div className="container">
                <div className="section-header">
                    <h2>{t('clients')}</h2>
                    <p>
                        {/*Sed tamen tempor magna labore dolore dolor sint tempor duis magna elit veniam aliqua esse amet*/}
                        {/*veniam enim export quid quid veniam aliqua eram noster malis nulla duis fugiat culpa esse aute*/}
                        {/*nulla ipsum velit export irure minim illum fore*/}
                    </p>
                </div>

                <FadeAnimation positionHidden={-75} positionVisible={0}>
                    <div className="image-client">
                        <img src={tomia} alt="" />
                        <img src={rfthings} alt="" />
                        <img src={navison} alt="" />
                        <img src={qtsc} alt="" />
                    </div>
                    {/* <div className="image-client">
                        <img src={clientImg1} alt="" />
                        <img src={clientImg2} alt="" />
                        <img src={clientImg3} alt="" />
                        <img src={clientImg4} alt="" />
                    </div> */}

                    {/* <Slider {...settings}>
                        <img src={clientImg1} alt="" />
                        <img src={clientImg2} alt="" />
                        <img src={clientImg3} alt="" />
                        <img src={clientImg4} alt="" />
                        <img src={clientImg5} alt="" />
                        <img src={clientImg6} alt="" />
                        <img src={clientImg7} alt="" />
                        <img src={clientImg8} alt="" />
                    </Slider> */}

                    {/* <OwlCarousel>
                    <div className="owl-carousel clients-carousel">
                        <img src={clientImg1} alt="" />
                        <img src={clientImg2} alt="" />
                        <img src={clientImg3} alt="" />
                        <img src={clientImg4} alt="" />
                        <img src={clientImg5} alt="" />
                        <img src={clientImg6} alt="" />
                        <img src={clientImg7} alt="" />
                        <img src={clientImg8} alt="" />
                    </div>
                    </OwlCarousel> */}
                </FadeAnimation>
            </div>
        </section>
    );
};

export default Clients;
