import { createSlice } from '@reduxjs/toolkit';

export interface CounterState {
    value: string;
}

const initialState: CounterState = {
    value: '',
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        showHeader: (state) => {
            state.value = '';
            console.log(state.value);
        },
        hideHeader: (state) => {
            state.value = 'hide';
            console.log(state.value);
        },
    },
});

// Action creators are generated for each case reducer function
export const { showHeader, hideHeader } = headerSlice.actions;

export default headerSlice.reducer;
