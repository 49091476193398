import React, { FC, useEffect, useState } from 'react';
import './Header.css';
import { LANGUAGES } from '../../constants';

import { useTranslation } from 'react-i18next';
// import logo from '../../asset/img/logo2.png';
import logo from '../../asset/img/logo.svg';

import { Link } from 'react-scroll';
import type { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

interface HeaderProps {
    class: string;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
    const header = useSelector((state: RootState) => state.header.value);

    const { t, i18n } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    const [language, setLanguage] = useState(i18n.language);

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const changeLang = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const lang: HTMLButtonElement = e.currentTarget;
        i18n.changeLanguage(lang.value);
    };

    React.useEffect(() => {
        setLanguage(i18n.language);
    }, [changeLang]);

    const styleActive = {
        color: '#1C9DD9',
    };

    const openNav = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    //choose the screen size
    // create an event listener
    React.useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const handleNav = () => {
        isMobile && setIsNavExpanded(!isNavExpanded);
    };

    return (
        <header id="header" className={header}>
            <div className="container">
                <div id="logo" className="pull-left">
                    <a href="/" className="scrollto">
                        <img className="logo" src={logo} alt="" />
                    </a>
                </div>
                <nav id="nav-menu-container">
                    <div className="navigation-menu">
                        <button onClick={openNav} id="mobile-nav-toggle">
                            <i className="fa fa-bars"></i>
                        </button>
                        <ul className={isNavExpanded ? 'nav-menu expanded' : 'nav-menu'}>
                            <li className={props.class}>
                                <Link
                                    onClick={handleNav}
                                    activeStyle={styleActive}
                                    to="slide"
                                    spy={true}
                                    offset={-300}
                                >
                                    {t('home')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={handleNav}
                                    activeStyle={styleActive}
                                    to="products"
                                    spy={true}
                                    offset={-300}
                                >
                                    {t('products')}
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link*/}
                            {/*        onClick={handleNav}*/}
                            {/*        activeStyle={styleActive}*/}
                            {/*        to="solutions"*/}
                            {/*        spy={true}*/}
                            {/*        offset={-70}*/}
                            {/*    >*/}
                            {/*        {t('solutions')}*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link
                                    onClick={handleNav}
                                    activeStyle={styleActive}
                                    to="clients"
                                    spy={true}
                                    offset={-150}
                                >
                                    {t('clients')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={handleNav}
                                    activeStyle={styleActive}
                                    to="contact"
                                    spy={true}
                                    offset={-300}
                                >
                                    {t('contact')}
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <button className="languages">
                                        <div id="flag" className={language}></div>
                                    </button>
                                    <div id="dropdownlist" className="dropdown-content">
                                        {LANGUAGES.map(({ className, flag, code, label }) => (
                                            <button onClick={changeLang} key={code} value={code}>
                                                <img className={className} src={flag} />
                                                <p>{label}</p>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
