import React, { FC, useRef, useEffect } from 'react';
import './AboutSection.css';
// import aboutImg from '../../asset/img/about-img.jpg';
import aboutimg from '../../asset/img/frame.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { motion, useInView, useAnimation } from 'framer-motion';
import FadeAnimation from '../FadeAnimation/FadeAnimation';
import { Trans, useTranslation } from 'react-i18next';

interface AboutSectionProps {}

const AboutSection = () => {
    const { t } = useTranslation();

    return (
        <FadeAnimation positionHidden={-75} positionVisible={0}>
            <section id="about" className="wow fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 content section-header">
                            <h2>{t('aboutus')}</h2>
                            <p>
                                <Trans i18nKey="aboutuscontent" />
                            </p>
                        </div>
                        <div className="col-lg-7 about-img">
                            <img src={aboutimg} alt="" />
                            <img src="" alt="" />
                        </div>
                    </div>
                </div>
                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-6 about-img">
                            <img src={aboutImg} alt="" />
                            <img src="" alt="" />
                        </div>

                        <div className="col-lg-6 content">
                            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing</h2>
                            <h3>
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                mollit anim id est laborum.
                            </h3>

                            <div className="description-content">
                                <i>
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                </i>
                                <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, inventore.</div>
                            </div>
                            <div className="description-content">
                                <i>
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                </i>
                                <div>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</div>
                            </div>
                            <div className="description-content">
                                <i>
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                </i>
                                <div></div>
                                Du lobur is aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                fugiat nulla pariatur trinige zareta trade..
                            </div>
                            <div className="description-content">
                                <i>
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                </i>
                                <div></div>
                                Ex zanox cepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum rideta satirente madera.
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </FadeAnimation>
    );
};

export default AboutSection;
