import React from 'react';
import './Products.css';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import CardProduct from '../CardProduct/CardProduct';
import greentimeimage from '../../asset/img/greentime-rightview.png';
import greenvisionimage from '../../asset/img/greenvision.jpg';
function Product() {
    const { t } = useTranslation();

    const gvisionContent = [
        {
            title: t('Features'),
            description: [
                t('Edge-to-Cloud Technology'),
                t('Live Streaming'),
                t('Data Storage Management: Videos, Audios, Images, and Documents'),
                t('Unlimited Scalability'),
            ],
        },
        {
            title: t('Integrations'),
            description: [
                t("It's easy to add new AI services.Integration done as plugins"),
                t('Open APIs for third-party softwares'),
            ],
        },
        {
            title: t('Hardware'),
            description: [
                t('Server-side: Build on top of any hardware running on Linux'),
                t('Client-side: Windows Desktop App, Administration Web, Mobile App'),
                t('Edge: Built on Embedded Vision Linux'),
                t('End devices: ESP chips, ST chips, etc'),
            ],
        },
    ];

    const gtimeContent = [
        {
            title: t('Features'),
            description: [
                t("Works on smart phones, both private devices or company's shared devices"),
                t('Can work offline'),
                t('Geo fence'),
                t(
                    'Fit to any business that needs to tie together a verified face, a time, a geo location with any other data such as questionnaire, barcode, pictures...',
                ),
            ],
        },
        {
            title: t('Integrations'),
            description: [
                t('Green Vision Platform'),
                t('Integratable with third-party softwares such as Payroll, ERP...'),
            ],
        },
        {
            title: t('Hardware'),
            description: [
                t('Android, iOS devices'),
                t('Support both cloud and on-premise servers'),
            ],
        },
    ];

    return (
        <section id="products">
            <div className="container">
                <div className="section-header">
                    <h2>{t('products')}</h2>
                </div>

                <CardProduct
                    flex="d-flex flex-row"
                    productTitle={t('Green Vision Platform')}
                    contents={gvisionContent}
                    imgSrc={greenvisionimage}
                />
                <CardProduct
                    flex="d-flex flex-row-reverse"
                    productTitle={'Green Time'}
                    contents={gtimeContent}
                    imgSrc={greentimeimage}
                />
            </div>
        </section>
    );
}

export default Product;
