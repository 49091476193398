import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './CardProduct.css';


interface CardProps {
    productTitle: string;
    contents: any;
    flex: string;
    imgSrc: string;
}

const CardProduct = (props: CardProps) => {
    const { productTitle, contents, flex, imgSrc } = props;

    return (
        <div className={flex + ' row card-container'}>
            <div className="d-flex align-items-center col-lg-4">
                <div className="product-image">
                    <img src={imgSrc} alt="" />
                </div>
            </div>
            <div className="col-lg-8">
                <div className="product-title">{productTitle}</div>
                <div className="row">
                    {contents.map((content: any, index: number) => {
                        return (
                            <div key={index} className="col-lg-6 product-child">
                                <div className="mb-2">
                                    <button className="product-name">{content.title}</button>
                                </div>
                                {content.description.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="product-description">
                                            <i>
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                            </i>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CardProduct;
