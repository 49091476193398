import React from 'react';
import { motion } from 'framer-motion';

type FadeAnimationProps = {
    children: React.ReactNode;
    positionHidden: number;
    positionVisible: number;
};

const FadeAnimation = (props: FadeAnimationProps) => {
    return (
        <motion.div
            variants={{
                hidden: { opacity: 0, y: props.positionHidden },
                visible: { opacity: 1, y: props.positionVisible },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.5 }}
            whileInView="visible"
            viewport={{ once: true }}
        >
            {props.children}
        </motion.div>
    );
};

export default FadeAnimation;
