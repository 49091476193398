import React from 'react';
import TopBar from '../../components/TopBar/TopBar';
import Header from '../../components/Header/Header';
import NavisonPolicy from '../../components/Policies/NavisonPolicy';

function NavisonPrivacyPolicy() {
    return (
        <>
            <TopBar />
            <Header class="menu-active" />
            <NavisonPolicy />
        </>
    );
}

export default NavisonPrivacyPolicy;
