import React, { FC } from 'react';
import './IntroSection.css';
import { useTranslation } from 'react-i18next';

import { Fade, Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import slide1 from '../../asset/img/slide/apartment.png';
import slide2 from '../../asset/img/slide/building.png';
import slide3 from '../../asset/img/slide/school.png';
import slide4 from '../../asset/img/slide/resort.png';

interface IntroSectionProps {}

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000',
};

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '110vh',
};

const slideImages = [
    // {
    //     url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    //     caption: 'Slide 1',
    // },
    // {
    //     url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
    //     caption: 'Slide 2',
    // },
    // {
    //     url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    //     caption: 'Slide 3',
    // },
    {
        url: slide1,
        caption: 'Slide 1',
        // display: 'none',
    },
    {
        url: slide2,
        caption: 'Slide 2',
    },
    {
        url: slide3,
        caption: 'Slide 3',
    },
    {
        url: slide4,
        caption: 'Slide 3',
    },
];

const buttonStyle = {
    fontSize: '30px',
    background: 'none',
    border: '0px',
    padding: '10px',
};

const properties = {
    prevArrow: (
        <button style={{ ...buttonStyle }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
        </button>
    ),
    nextArrow: (
        <button style={{ ...buttonStyle }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
        </button>
    ),
};

const IntroSection: FC<IntroSectionProps> = () => {
    const { t } = useTranslation();
    return (
        // <div>
        //     <section id="intro">
        //         <div className="intro-content">
        //             <h2>
        //                 {t('make')}
        //                 <span>{t('yourideas')}</span>
        //                 <br />
        //                 {t('happen')}
        //             </h2>
        //             <div>
        //                 <a href="#about" className="btn-get-started scrollto">
        //                     Get Started
        //                 </a>
        //                 <a href="#portfolio" className="btn-projects scrollto">
        //                     Our Projects
        //                 </a>
        //             </div>
        //         </div>

        //         <div id="intro-carousel" className="owl-carousel">
        //             <div className="item" style={{ backgroundImage: `url('img/intro-carousel/1.jpg')` }}></div>
        //             <div className="item" style={{ backgroundImage: `url('img/intro-carousel/2.jpg')` }}></div>
        //             <div className="item" style={{ backgroundImage: `url('img/intro-carousel/3.jpg')` }}></div>
        //             <div className="item" style={{ backgroundImage: `url('img/intro-carousel/4.jpg')` }}></div>
        //             <div className="item" style={{ backgroundImage: `url('img/intro-carousel/5.jpg')` }}></div>
        //         </div>
        //     </section>
        // </div>
        <div id="slide" className="slide-container">
            <Slide cssClass="slide-wrap" duration={3000} transitionDuration={750} {...properties}>
                {slideImages.map((slideImage, index) => (
                    <div key={index}>
                        <div
                            className="slide-image"
                            style={{
                                ...divStyle,
                                backgroundImage: `url(${slideImage.url})`,
                                // display: slideImage.display,
                            }}
                        >
                            {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                        </div>
                    </div>
                ))}
            </Slide>
            {/* <Fade>
                {slideImages.map((fadeImage, index) => (
                    <div key={index}>
                        <img style={{ width: '100%' }} src={fadeImage.url} />
                        <h2>{fadeImage.caption}</h2>
                    </div>
                ))}
            </Fade> */}
        </div>
    );
};

export default IntroSection;
