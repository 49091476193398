import React, { FC, FormHTMLAttributes, useRef, useState } from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FadeAnimation from '../FadeAnimation/FadeAnimation';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import emailjs, { send } from '@emailjs/browser';

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ContactUsProps {
    namevalidate: string;
    emailvalidate: string;
    subjectvalidate: string;
    messagevalidate: string;
}

const ContactUs: FC<ContactUsProps> = (props: ContactUsProps) => {
    const FormSchema = z.object({
        username: z.string().min(1, { message: props.namevalidate }),
        // .max(10, { message: 'Your name must be 10 characters or less' }),
        // .regex(/^[a-zA-Z0-9_]+$/, 'Your name must contain only letters, numbers and underscore (_)')
        email: z.string().email({
            message: props.emailvalidate,
        }),
        subject: z.string().min(1, { message: props.subjectvalidate }),
        message: z.string().min(1, { message: props.messagevalidate }),
        createdAt: z.coerce.date(),
    });

    type FormInput = z.infer<typeof FormSchema>;

    const { t } = useTranslation();
    const form = useRef<HTMLFormElement>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            username: '',
            email: '',
            subject: '',
            message: '',
            createdAt: new Date(),
        },
    });

    const onSubmit = (data: FormInput, e: any) => {
        console.log(data);
        e.preventDefault();
        // toast.success(t('toastmessagesuccess'));

        //Send message form to email
        // emailjs.sendForm('service_qd7moga', 'template_n41cgwj', e.target, 'NC0AGqI8jIeyZzO8S').then(
        //     (result) => {
        //         console.log(result.text);
        //         toast.success(t('toastmessagesuccess'));
        //     },
        //     (error) => {
        //         console.log(error.text);
        //         toast.error(t('toastmessageerror'));
        //     },
        // );
    };
    return (
        <section id="contact" className="wow fadeInUp">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Zoom}
            />
            <div className="container">
                <div className="section-header">
                    <h2>{t('contact')}</h2>
                </div>

                <FadeAnimation positionHidden={-75} positionVisible={0}>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contact-info">
                                <div className="d-flex align-items-center contact-phone">
                                    <p>
                                        <i>
                                            <FontAwesomeIcon icon={faPhone} />
                                        </i>
                                    </p>
                                    <div className="m-3">
                                        <h3 className="m-0">{t('phone')}</h3>
                                        <p>
                                            <a href="tel:0936419933">(+84) 936419933</a>
                                        </p>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center contact-email">
                                    <p>
                                        <i>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </i>
                                    </p>
                                    <div className="m-3">
                                        <h3 className="m-0">{t('email')}</h3>
                                        <p>
                                            <a href="mailto:info@greenstreamvision.com">
                                                info@greenstreamvision.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center contact-address">
                                    <p>
                                        <i>
                                            <FontAwesomeIcon icon={faLocationDot} />
                                        </i>
                                    </p>
                                    <div className="m-3">
                                        <h3 className="m-0">{t('address')}</h3>
                                        <address>{t('addressinfo')}</address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div id="sendmessage">
                                <div className="form">
                                    <form
                                        ref={form}
                                        // action="mailto:hoapeo2208@gmail.com"
                                        onSubmit={handleSubmit(onSubmit)}
                                        // onSubmit={sendEmail}
                                        role="form"
                                        className="contactForm"
                                    >
                                        <div className="form-group">
                                            <label>{t('yourname')}:</label>
                                            <input
                                                className="form-control"
                                                data-rule="minlen:4"
                                                id="username"
                                                {...register('username')}
                                            />
                                            <div className="errormessage">
                                                {errors?.username?.message && (
                                                    <p>{errors.username.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('youremail')}:</label>

                                            <input
                                                className="form-control"
                                                data-rule="email"
                                                id="email"
                                                {...register('email')}
                                            />
                                            <div className="errormessage">
                                                {errors?.email?.message && (
                                                    <p>{errors.email.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('subject')}:</label>
                                            <input
                                                className="form-control"
                                                data-rule="minlen:4"
                                                id="subject"
                                                {...register('subject')}
                                            />
                                            <div className="errormessage">
                                                {errors?.subject?.message && (
                                                    <p>{errors.subject.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('message')}:</label>
                                            <textarea
                                                className="form-control"
                                                rows={6}
                                                data-rule="required"
                                                id="message"
                                                {...register('message')}
                                            ></textarea>
                                            <div className="errormessage">
                                                {errors?.message?.message && (
                                                    <p>{errors.message.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="btn-send">
                                            <button type="submit">{t('sendmessage')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeAnimation>
            </div>
        </section>
    );
};

export default ContactUs;
