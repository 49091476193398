import React, { Suspense, useEffect } from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import NavisonPrivacyPolicy from './pages/PrivacyPolicy/NavisonPrivacyPolicy';
import GreenTimePrivacyPolicy from './pages/PrivacyPolicy/GreenTimePrivacyPolicy';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="inavision-privacy-policy" element={<NavisonPrivacyPolicy />} />
            <Route path="green-time-privacy-policy" element={<GreenTimePrivacyPolicy />} />
        </Routes>
    );
}

export default App;
