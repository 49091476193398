import React, { useEffect, useRef } from 'react';
import './Home.css';
import Header from '../../components/Header/Header';
import TopBar from '../../components/TopBar/TopBar';
import IntroSection from '../../components/IntroSection/IntroSection';
import AboutSection from '../../components/AboutSection/AboutSection';
import Solutions from '../../components/Solutions/Solutions';
import Clients from '../../components/Clients/Clients';
import Portfolio from '../../components/Portfolio/Portfolio';
import Testimonials from '../../components/Testimonials/Testimonials';
import CallToAction from '../../components/CallToAction/CallToAction';
import OurTeam from '../../components/OurTeam/OurTeam';
import ContactUs from '../../components/ContactUs/ContactUs';
import Footer from '../../components/Footer/Footer';
import Services from '../../components/Services/Services';
import Product from '../../components/Products/Products';

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();

    return (
        <motion.div
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={'visible'}
            transition={{ duration: 0.5, delay: 0.25 }}
            whileInView="visible"
            viewport={{ once: true }}
        >
            <TopBar />
            <Header class="menu-active" />
            <IntroSection />
            <AboutSection />
            <Product />
            {/*<Solutions />*/}
            {/*<Services />*/}
            <Clients />
            {/* <Portfolio /> */}
            {/* <Testimonials /> */}
            {/*<CallToAction />*/}
            {/* <OurTeam /> */}
            <ContactUs
                namevalidate={t('namevalidate')}
                emailvalidate={t('emailvalidate')}
                subjectvalidate={t('subjectvalidate')}
                messagevalidate={t('messagevalidate')}
            />
            <Footer />
        </motion.div>
    );
}

export default Home;
