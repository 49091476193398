import React from 'react';
import './BannerPolicy.css';

const NavisonPolicy = () => {
    return (
        <>
            <div className="banner">
                <div className="image"></div>
                <div className="text-center">Privacy Policy - iNavison</div>
            </div>
            <div className="policy-container container">
                <div className="title">Privacy Policy</div>
                <div className="description">
                    Green Stream Technology Co. LTD ("us", "we", or "our") operates the iNavison
                    mobile application (the "Service").
                </div>
                <div className="description">
                    This page informs you of our policies regarding the collection, use, and
                    disclosure of personal data when you use our Service and the choices you have
                    associated with that data.
                </div>
                <div className="title">Information Collection and Use</div>
                <div className="description">
                    We collect and use personal data for the purpose of enabling Bluetooth pairing
                    within the iNavison application. This includes the precise location of your
                    device for the sole purpose of facilitating Bluetooth connections.
                </div>
                <div className="title">Types of Data Collected</div>
                <h2>Location Data</h2>
                <p>
                    iNavison collects precise location data when Bluetooth pairing is initiated.
                    This information is used exclusively for the purpose of establishing Bluetooth
                    connections and is not shared with any third parties.
                </p>
                <div className="title">Use of Data</div>
                <div className="description">
                    The data collected is used solely for the purpose of enabling Bluetooth pairing
                    within the iNavison application. We do not share this data with any third
                    parties or use it for any other purposes.
                </div>
                <div className="title">Security</div>
                <div className="description">
                    We value your trust in providing us your personal information, and we are
                    committed to protecting that information. However, no method of transmission
                    over the internet or method of electronic storage is 100% secure and reliable,
                    and we cannot guarantee its absolute security.
                </div>
                <div className="title">Changes to This Privacy Policy</div>
                <div className="description">
                    We may update our Privacy Policy from time to time. Thus, we advise you to
                    review this page periodically for any changes. We will notify you of any changes
                    by posting the new Privacy Policy on this page. These changes are effective
                    immediately after they are posted on this page.
                </div>
                <div className="title">Contact Us</div>
                <div className="description">
                    If you have any questions or suggestions about our Privacy Policy, do not
                    hesitate to contact us.
                </div>
            </div>
        </>
    );
};

export default NavisonPolicy;
