import React, { FC } from 'react';
import './Footer.css';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
    <>
        <footer id="footer">
            <div className="container">
                <div className="copyright">&copy; Copyright Green Stream. All Rights Reserved</div>
            </div>
        </footer>
    </>
);

export default Footer;
